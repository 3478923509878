@use "sass:math";

.LanguagePicker {


	@mixin collapsed__toggle() {
		max-width: 40px;
	}

	&__toggle {
		max-width: 200px;
		height: 40px;
		padding: 8px !important;
		white-space: nowrap;
		overflow: hidden;

		transition: max-width math.div($ease-delay, 1.5) $ease-standard;

		&.dropdown-toggle::after {
			display: none;
		}
	}

	&--collapsed &__toggle {
		@include  collapsed__toggle();
	}

	@include media-breakpoint-down(md) {
		&__toggle {
			@include collapsed__toggle();
		}
	}

	&__toggle-text {
		display: inline-block;
		margin-left: 8px;
	}

	.dropdown-menu {
		border: none;
		margin-top: -4px;
	}

	.dropdown-item {
		padding: 9px 16px;
		line-height: 24px;
		&:hover, &:active {
			background-color: rgba(0, 0, 0, 0.14);
		}
	}

}