.PanoramaPreview {
	&, &:hover, &:focus {
		text-decoration: none;
		color: inherit;
	}

	&__panorama {
		background-position: center center;
		background-size: cover;
		opacity: 1;
	}


	&__overlay {
		position: absolute;
		z-index: 2;
		left: 0; top: 0;
		bottom: 0; right: 0;
		width: 100%; height: 100%;
	}

	&__title,
	&__description {
		white-space: nowrap;
		overflow: hidden;
		-ms-text-overflow: ellipsis;
		text-overflow: ellipsis;
	}


	&:hover {
		.PanoramaPreview__panorama {
			opacity: 0.9;
		}
	}

}