@use "sass:math";

.SideContent {
	$coverHeight: 62%;

	position: fixed;
	top: 0; height: 100%;
	left: 0; width: 440px;
	background: $light;

	overflow: auto;

	transform: translateX(-100%);
	transition:
		transform $ease-delay $ease-acceleration,
		width $ease-delay $ease-standard;

	&--show {
		transform: translateX(0);
		transition:
			transform $ease-delay $ease-deceleration,
			width math.div($ease-delay, 1.5) $ease-standard;
	}

	&--fullscreen {
		@include media-breakpoint-up(sm) {
			width: 90%;
			width: calc(100% - 200px);
			min-width: 440px;
		}
	}


	@include media-breakpoint-down(xs) {
		top: 100%;
		width: auto;
		right: 16px;
		left: 16px;
		transform: none;
		transition: top $ease-delay $ease-standard,
		left $ease-delay $ease-standard,
		right $ease-delay $ease-standard;

		&--show {
			top: 0;
			left: 0;
			right: 0;
		}
	}

	&__inner {
		height: 100%;
	}

	&__cover {
		position: relative;
		height: $coverHeight;
		transition: height $ease-delay $ease-standard;
		background: #5577aa;
	}

	&__cover-controls {
		position: absolute;
		display: block;
		right: 8px; bottom: 8px;
		background: #fff;
		border-radius: $border-radius;
		overflow: hidden;
		z-index: 2;
	}

	&__cover-control {
		display: inline-block;
		width: 40px;
		height: 40px;
		padding: 8px;
		background: #fff;
	}

	&--fullscreen &__cover {
		height: 100%;
	}

	@include media-breakpoint-down(xs) {
		&__inner {
			transition: top $ease-delay $ease-standard;
			position: relative;

			top: -$coverHeight;
		}
		&--show &__inner {
			top: 0;
		}
	}

	&__header {
		height: 56px;
		background: #5577aa;
		position: relative;
		color: #fff;
	}

	&__header-controls {
		margin-top: 8px;
		margin-right: 8px;
		float: right;
		line-height: 1;
	}

	&__header-control {
		display: inline-block;
		width: 40px;
		height: 40px;
		padding: 8px;
		color: #fff;
	}

	&__title {
		font-weight: normal;
		font-size: 1rem;
		margin: 0;
		padding: 8px 8px 8px 16px;
		line-height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
	}


	&__btn-expand {
		position: absolute;
		left: 8px; right: 8px;
		top: 50%; margin-top: -20px;
		padding: 8px;
		line-height: 1;
		text-align: right;

		display: none;
		@include media-breakpoint-down(xs) {
			display: block;
		}

		color: rgba(#fff, 0.5);
		&:hover {
			color: rgba(#fff, 1);
		}

		.Icon {
			transform: rotateZ(-180deg);
			transition: transform $ease-delay $ease-standard;
		}
	}

	&__content {
		padding: 16px;
	}

}
