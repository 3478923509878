.Logo {

	$scaleLogo: 26*0.0125;
	$scaleCaption: 40*0.0125;
	$scaleXXS: 0.8;

	@include text-hide();

	opacity: 0.65;
	margin: 0;
	padding: 0;

	@include media-breakpoint-up(sm) {
		margin-right: 32px;
	}

	a:hover & {
		opacity: 0.75;
	}

	&__logo, &__caption {
		display: inline-block;
		background-size: contain;
		vertical-align: middle;
		margin: 0;
	}

	&__logo {
		width: 230px * $scaleLogo;
		height: 80px * $scaleLogo;
		background: url('res/logo__logo.svg') no-repeat center center;
		margin-top: 4px;
		margin-left: 8px;
	}

	&__caption {
		width: 310px * $scaleCaption;
		height: 80px * $scaleCaption;
		background: url('res/logo__caption.svg') no-repeat center center;
		margin: -3px 0 0 16px;
	}

	@media (max-width: 359.98px) {

		&__logo {
			width: 230px * $scaleLogo * $scaleXXS;
			height: 80px * $scaleLogo * $scaleXXS;
		}
		&__caption {
			width: 310px * $scaleCaption * $scaleXXS;
			height: 80px * $scaleCaption * $scaleXXS;
		}
	}

}
