.Map {
	height: 62%;
	min-height: 300px;
	position: relative;
	background-color: #7a7;

	transition: height $ease-delay $ease-acceleration;

	&--fullscreen {
		height: 100%;
		transition: height $ease-delay $ease-deceleration;
	}

	&__overlay {
		background-color: rgba( #bfb, 0.6);
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;

		opacity: 0;
		transition: opacity $ease-delay $ease-standard;
		&--active {
			opacity: 1;
		}
	}

	&__jumbotron {
		position: absolute;
		left: 0; right: 0;
		top: 56px; bottom: 0;
		color: #000;
	}

	&__wrapper {
		height: 100%;
	}

}

