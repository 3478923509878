@use "sass:math";

.Search {
	position: relative;
	background: #f0f0f0;
	height: 40px;
	padding: 8px;
	z-index: 2;
	transition:
		width math.div($ease-delay, 1.5) $ease-standard,
		margin-right math.div($ease-delay, 1.5) $ease-standard;

	border-radius: $border-radius;

	margin-left: auto;
	flex: 1;
	max-width: 400px;
	margin-right: 0;

	@include media-breakpoint-up(md) {
		margin-right: 16px;
	}

	@include media-breakpoint-up(lg) {
		margin-right: 24px;
	}

	@include media-breakpoint-only(lg) {
		max-width: 300px;
	}

	&__wrapper {
		height: 24px;
		line-height: 24px;
		display: block;
		margin-right: 8px + 8px + 24px;
	}

	&__button {
		position: absolute;
		top: 0; right: 0;
		width: 40px; height: 40px;
		padding: 8px;
		cursor: pointer;
		color: rgba(#000, 0.6);
		&:hover {
			color: rgba(#000, 0.9);
		}
	}

	&__input {
		border: none;
		background: transparent;
		width: 100%;
		&, &:focus {
			outline: none;
		}
	}

	&__items {
		position: absolute;
		left: 0;
		right: 0;
		background: #fff;
		padding: 0;
		border-radius: 0 0 $border-radius $border-radius;
		max-height: calc(100vh - 150px);
		overflow: auto;
	}

	&__item {
		cursor: pointer;
		padding: 9px 16px;

		&--highlighted {
			background-color: rgba(0, 0, 0, 0.14);
		}
	}


	@mixin collapsed() {
		width: 40px;
		flex: none;
		background-color: $light;
		margin-right: 0;
		max-width: none;
	}

	@mixin collapsed__wrapper() {
		margin-left: 8px + 24px + 8px;
		margin-right: 0;
	}

	@mixin collapsed__button() {
		right: 0;
	}

	@mixin collapsed__items() {
		top: 48px;
	}

	@mixin collapsed--active() {
		position: absolute;
		left: 0; right: 0; width: auto;
		top: 0; bottom: 0; height: auto;
		padding: 16px;
		border-radius: $border-radius $border-radius 0 0;
		background: #fff;
	}

	@mixin collapsed--active__button() {
		left: 8px;
		top: 8px;
		right: auto;
		color: rgba(#000, 0.9);
	}

	&--collapsed {
		@include collapsed();
	}

	&--collapsed &__wrapper {
		@include collapsed__wrapper();
	}

	&--collapsed &__button {
		@include collapsed__button;
	}

	&--collapsed &__items {
		@include collapsed__items();
	}

	&--collapsed#{&}--active {
		@include collapsed--active();
	}

	&--collapsed#{&}--active &__button {
		@include collapsed--active__button();
	}



	@include media-breakpoint-down(sm) {

		@include collapsed();

		&__wrapper {
			@include collapsed__wrapper();
		}

		&__button {
			@include collapsed__button();
		}

		&__items {
			@include collapsed__items();
		}

		&--active {
			@include collapsed--active();
		}

		&--active &__button {
			@include collapsed--active__button();
		}
	}

}
