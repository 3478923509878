.Content {
	background-color: #fff;

	&__footer {
		background: #eee;
		border-top: solid 1px #ddd;
		padding: 15px 0;
		margin-top: 30px;
	}
}
