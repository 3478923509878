@use "sass:math";

$offset: 8px;
$offset-sm: 16px;

.Header {

	position: fixed;
	z-index: 2;
	left: 0;
	width: 100%;
	top: 0;
	padding: 8px;

	& > .container {
		padding-left: 0;
		padding-right: 0;
		min-width: 335px;
		@media (max-width: 359.98px) {
			min-width: 295px;
		}
		@include media-breakpoint-up(lg) {
			flex-wrap: nowrap;
		}
	}

	transition:
		left math.div($ease-delay, 1.5) $ease-standard,
		width math.div($ease-delay, 1.5) $ease-standard,
		top $ease-delay $ease-standard,
		height $ease-delay $ease-standard,
		box-shadow $ease-delay $ease-standard;

	@extend .shadow--1;



	&--collapsed {
		top: $offset;
		left: $offset;
		width: calc(100% - #{$offset*2});
		border-radius: $border-radius;

		@extend .shadow--2;

		@include media-breakpoint-up(sm) {
			top: $offset-sm;
			left: $offset-sm;
			width: 400px;
		}

	}


	&__eu {
		flex: 1 100%;
		text-align: left;
		height: 40px + 8px;
		width: 270px;
		overflow: hidden;
		white-space: nowrap;
		transition:
			width math.div($ease-delay, 1.5) $ease-standard,
			height math.div($ease-delay, 1.5) $ease-standard,
			opacity math.div($ease-delay, 1.5) $ease-standard;
		opacity: 1;

		@include media-breakpoint-up(lg) {
			flex: 0 auto;
			margin: 0 20px 0 -12px;
			height: 40px;
		}

		img {
			height: 40px;
			margin: 0 8px;
		}
	}

	@include media-breakpoint-up(lg) {
		.Logo {
			order: 1;
		}
		&__eu {
			order: 2;
		}
		.Search {
			order: 3;
		}
		ul.navbar-nav {
			order: 4;
		}
	}


	&--collapsed .Header__eu {
		height: 0;
		margin-bottom: 0;
		opacity: 0;

		@include media-breakpoint-up(lg) {
			width: 0;
			height: 40px;
		}

	}

}
